import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
  IonGrid,
  IonLabel,
  IonButton, IonIcon, IonRow
} from "@ionic/react";
import React, { useState, useRef } from "react";
import "./UserList.scss";
import UserCard from "../../../components/User/UserCard";
import { userService } from '../../../services';
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";
import AddFilter from "../../../components/Header/AddFilter/AddFilter";
import {cloudUploadOutline, downloadOutline, personAddOutline} from "ionicons/icons";
import useFilter from "../../../hooks/useFilter";
import useSearch from "../../../hooks/useSearch";
import useDataFlow from "../../../hooks/useDataFlow";
import UserImportModal from "../../../containers/user/UserImportModal";

const List = ({ list, getUserList }: any) => <>
  {list.map((user: any) => <UserCard key={user.id} user={user} getUserList={getUserList}/>)}
</>

const UserList: React.FC = () => {
  const isMountedRef = useRef<null | boolean>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [userListData, setUserListData] = useState([])
  const [userListDisplayed, setUserListDisplayed] = useState([])
  const [importModalOpen, setImportModalOpen] = useState(false)

  const { triggerSearch } = useSearch('user', setUserListDisplayed)
  const { triggerFilter, options } = useFilter('user')
  const { initDataFlow, dataFlow } = useDataFlow('user', userListData, [triggerFilter, triggerSearch])

  const onFilterChange = (text: string) => {
    dataFlow([
      text,
      localStorage.getItem('search')!
    ])
  }

  const onExport = () => {
    userService.exportList().then(data => {
      const blobURL = window.URL.createObjectURL(data.data);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', 'export-utilisateurs.csv');
      tempLink.click();
      tempLink.remove();
    }).catch((e) => {
      console.log(e);
    });
  }

  const onSearchChange = (text: string) => {
    dataFlow([
      localStorage.getItem('userfilter')!,
      text
    ])
  }

  const getUserList = () => {
    userService.getList().then(response => {
      if(isMountedRef.current) {
        setUserListData(response.data)

        const searchText = ''
        localStorage.setItem('search', searchText)
        initDataFlow([
          localStorage.getItem('userfilter')!,
          searchText
        ], response.data)
      }
    setIsLoading(false)
    }).catch(e => {})
  }

  useIonViewWillEnter(() => {
    isMountedRef.current = true;
    document.title = `Utilisateurs - Canreport`
    getUserList()
    return () => isMountedRef.current = false;
  }, [])

  return (
    <IonPage className="ion-page" id="main-content">
      <UserImportModal isOpen={importModalOpen} closeModal={() => setImportModalOpen(false)} />
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu active="utilisateurs" />
          <TitleOptionSearch
            title="Utilisateurs"
            listCount={userListDisplayed.length || "0"}
            onSearchChange={onSearchChange}
          />
          <IonRow>
            <IonRow className={'ion-justify-content-end'}>
              <IonButton fill={'outline'} onClick={() => setImportModalOpen(true)}>
                Importer en masse
                <IonIcon
                    className={'ion-margin-start'}
                    icon={cloudUploadOutline} />
              </IonButton>
              <IonButton fill={'outline'} onClick={onExport}>
                Exporter
                <IonIcon
                    className={'ion-margin-start'}
                    icon={downloadOutline} />
              </IonButton>
            </IonRow>

            <AddFilter
                type="user"
                addLink="/utilisateurs/creer"
                addLabel="Créer un utilisateur"
                addIcon={personAddOutline}
                onFilterChange={onFilterChange}
                options={options}
            />
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
          <List list={userListDisplayed} getUserList={getUserList} />
          {/* <EmptyList list={userListDisplayed} isSearching={isSearching} /> */}
          {userListDisplayed.length === 0 && !isLoading && <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '150px',
          }}>
            <IonLabel style={{marginBottom: '20px'}}>
              Aucun utilisateur
            </IonLabel>
          </div>}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default UserList;
