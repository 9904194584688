
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonGrid,
    IonRow,
    IonButton,
    IonIcon,
    IonLabel,
} from "@ionic/react";
import React, {useState, useEffect} from "react";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import TitleOptionSearch from "../../../components/Header/TitleOptionSearch/TitleOptionSearch";
import { subProjectService, userService } from "../../../services";
import "./SubProjectAcces.scss";
import {reloadOutline, lockClosedOutline, cloudUploadOutline, downloadOutline} from "ionicons/icons";
import AccessHeader from "../../../components/Access/AccessHeader";
import AccessCard from "../../../components/Access/AccessCard";
import Select from "react-select";
import PermissionsImportModal from "../../../containers/sub_project/permission/PermissionsImportModal";

const List = ({ list, projectId, subProjectId, permissionList, bookletList, updatePermissions }: any) => (
  <>
    {list.map((client: any) => {
        const { id, firstname, lastname } = client;

        const permissionTest = permissionList.length === 0 ? {} : permissionList.find((permission: any) => client.id === permission.user.id);
        const permissionValid = !!permissionTest ? permissionTest : {
          permissions: [],
          user: { id, firstname, lastname, }
        };

        return <AccessCard
            key={client.id}
            client={client}
            projectId={projectId}
            updatePermissions={updatePermissions}
            subProjectId={subProjectId}
            permission={permissionValid}
            bookletList={bookletList}
        />
    })}
  </>
);

const SubProjectAcces: React.FC = () => {

  const [permissionsImportModalOpen, setPermissionsImportModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject]: any = useState({});
  const [subProject, setSubProject]: any = useState({});
  const [clientList, setClientList]: any = useState();
  const [clientListDisplayed, setClientListDisplayed]: any = useState([])
  const [clientSelected, setClientSelected]: any = useState();
  const [options, setOptions]: any = useState([]);
  const [bookletList, setBookletList]: any = useState([]);
  const [permissionList, setPermissionList]: any = useState([]);

  const subProjectId = window.location.pathname.split('/').reverse()[1]!;

  const getPermissionList = (subProjectId: any) => {
    subProjectService.getPermissionList(subProjectId)
      .then(({ data: permissionListData }: any) => {
        setPermissionList(Object.values(permissionListData));
      })
      .catch(console.log);
  }

   const updatePermissions = () => {
       const subProjectId = window.location.pathname.split('/').reverse()[1]!
      getPermissionList(subProjectId);
   }

  const addClient = (selected: any) => {
    setIsSubmitting(true)
    const newClientListDisplayed: any[] = [
        clientList.find((client: any) => client.id === selected.value),
        ...clientListDisplayed,
    ];

    setClientListDisplayed(newClientListDisplayed);
    setClientSelected(null);

    refreshOptions(clientList, newClientListDisplayed);
    setIsSubmitting(false)
  }

  const refreshOptions = (users: any[], usersDisplayed: any[]) => {
      setOptions(users.filter((user: any) => !usersDisplayed.some((userDisplayed: any) => userDisplayed.id === user.id)).map((user: any) => ({
          value: user.id,
          label: `${user.firstname} ${user.lastname}\n${user.email}`
      })))
  }

  useEffect(() => {
        if(permissionsImportModalOpen) return;

        const projectId = window.location.pathname.split('/').reverse()[3]!
        const subProjectId = window.location.pathname.split('/').reverse()[1]!
        setProject({ id: projectId })
        setSubProject({ id: subProjectId })

        subProjectService.getOne(subProjectId)
          .then(({ data: subProjectData }: any) => {
            document.title = `${subProjectData.name} (accès) - Canreport`
            setSubProject(subProjectData)
          })
          .catch(console.log);

        subProjectService.getPermissionList(subProjectId)
            .then(({ data: permissionListData }: any) => {
                setPermissionList(Object.values(permissionListData))
                userService.getList()
                    .then(({ data: clientListData }: any) => {
                        const users = clientListData.filter((user: any) => user.enabled);
                        const usersListDisplayed = users.filter((client: any) => Object.values(permissionListData).some((permission: any) => client.id === permission.user.id));

                        setClientList(users);
                        setClientListDisplayed(usersListDisplayed);

                        refreshOptions(users, usersListDisplayed)
                    })
                    .catch(console.log);
            })
            .catch(console.log);


        subProjectService.getBookletList(subProjectId)
          .then(({ data: bookletListData }: any) => {
            setBookletList(bookletListData)
          })
          .catch(console.log);

        setIsLoading(false);
      }, [permissionsImportModalOpen]);

  const onExport = () => {
      subProjectService.exportPermissions(subProjectId).then(data => {
          const blobURL = window.URL.createObjectURL(data.data);
          const tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.setAttribute('download', 'export-permissions.csv');
          tempLink.click();
          tempLink.remove();
      }).catch((e) => {
          console.log(e);
      });
  }

  return (
    <IonPage className="ion-page" id="main-content">
      <PermissionsImportModal subProjectId={subProjectId} isOpen={permissionsImportModalOpen} closeModal={() => setPermissionsImportModalOpen(false)} />
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu active="projets" backLabel={subProject.name} backLink={`/projet/${project.id}/sous-projet/${subProject.id}`} />
          <TitleOptionSearch
            title="Accès"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonGrid className="center-content">
          <IonRow className="ion-justify-content-between">
              <IonRow className={'ion-justify-content-end'}>
                  <IonButton fill={'outline'} onClick={() => setPermissionsImportModalOpen(true)}>
                      Importer en masse
                      <IonIcon
                          className={'ion-margin-start'}
                          icon={cloudUploadOutline} />
                  </IonButton>
                  <IonButton fill={'outline'} onClick={onExport}>
                      Exporter
                      <IonIcon
                          className={'ion-margin-start'}
                          icon={downloadOutline} />
                  </IonButton>
              </IonRow>

                <IonRow className="ion-align-items-center">
                  <div style={{
                    width: '300px',
                    zIndex: 9999,
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    textOverflow: "string !important",
                  }}>
                    <Select
                      value={clientSelected}
                      onChange={setClientSelected}
                      placeholder="Accès au sous-projet"
                      width='200px'
                      menuColor='red'
                      options={options}
                    />
                  </div>
                <IonButton type="submit" disabled={isSubmitting} onClick={(e: any) => addClient(clientSelected)}>
                    <IonIcon
                      slot="start"
                      className={isSubmitting ? `rotate` : ``}
                      icon={isSubmitting ? reloadOutline : lockClosedOutline}
                    />
                    <IonLabel style={{ textTransform: "none" }}>
                      {isSubmitting ? "Chargement" : "Ajouter"}
                    </IonLabel>
                </IonButton>
              </IonRow>
            {/* </form> */}
          </IonRow>
          {clientListDisplayed.length > 0 && <AccessHeader />}
          {clientListDisplayed.length > 0 && <List list={clientListDisplayed} projectId={project.id} subProjectId={subProject.id} permissionList={permissionList} bookletList={bookletList} getPermissionList={getPermissionList} updatePermissions={updatePermissions} />}
          {permissionList.length === 0 && !isLoading && <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '150px',
          }}>
            <IonLabel style={{marginBottom: '20px'}}>
              Aucune permission définie
            </IonLabel>
          </div>}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SubProjectAcces;
