import './TaskCard.scss';
import {
  IonCard,
  IonCardHeader,
  IonRow,
  IonCardTitle,
  IonLabel,
  IonButtons,
  IonButton,
  IonIcon,
  IonCardContent,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonCol,
} from '@ionic/react';
import { chipColor } from './Chip';
import React, {useState, useEffect, useContext} from 'react';
import {
  saveOutline,
  closeOutline,
  createOutline,
  trash,
  documentOutline,
  chatbubbleEllipsesOutline,
  warningOutline, checkboxOutline, listOutline
} from 'ionicons/icons';
import { ITask } from '../../Interface/Task';
import { taskService, pointingService, userService } from '../../services';
import useDate from '../../hooks/useDate';
import Chip from "./Chip";
import ReactTooltip from "react-tooltip";
import {SubprojectDetailContext} from "../../contexts/SubprojectDetailContext";
import CheckListTaskModal from "./CheckListTaskModal";

const TaskCard: React.FC<any> =  ({ task: taskInput, breadcrumb = '' }: any) => {
  const [task, setTask] = useState<ITask>(taskInput);
  const [customProgress, setCustomProgress] = useState<string>();
  const [lastProgress, setLastProgress] = useState<number | undefined>();
  const [isTaskUnfolded, setIsTaskUnfolded] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const [checkListModalOpen, setCheckListModalOpen] = useState(false);

  const [isSelectingCustomAdvancement, setIsSelectingCustomAdvancement] = useState(false);
  const { formatDate } = useDate();

  const { subProject, showSignatureModal, permissionList, documentList, showModal, showRexModal } = useContext(SubprojectDetailContext);

  const cancelTask = () => {
    const savedProgress = task.progress;
    task.cancelled = true
    task.progress = 100
    setTask({...task});
    taskService.cancel(task.id ?? '').catch(() => {
      task.cancelled = false
      task.progress = savedProgress
      setTask({...task});
    })
  }

  const reportOrCancelTask = () => {
    task.reported = !task.reported;
    setTask({...task});
    taskService.report(task.id ?? '').catch(() => {
      task.reported = !task.reported;
      setTask({...task});
    });
  }

  const handleChangeToNone = () => {
    if(task.started) {
      taskService.reset(task.id ?? '').then(() => {
        task.progress = 0;
        task.started = false;
        setTask({...task });
      })
    } else {
      submitProgressChange(0)
      task.started = true;
    }
  }

  const handleChange = (val: number) => {
    if(val !== 0) submitProgressChange(val)
  }

  const submitProgressChange = (value: any) => {
    setCustomProgress(value);
    setLastProgress(task.progress);
    submit(value);
    task.progress = value;
  }

  const handleCustomProgress = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const entryStr = e.target.value;
    const entryNbr = parseInt(entryStr, 10);
    if (entryStr === '' || (/^[0-9\b]+$/.test(entryStr) && entryNbr >= 0 && entryNbr <= 100)) {
      setCustomProgress(entryStr);
    }
  }

  const cancelCustomProgress = () => {
    setCustomProgress(task.progress?.toString());
    setIsSelectingCustomAdvancement(false);
  };

  const submit = (progress: any) => {
    const progressParse = parseInt(progress, 10)
    if ((progressParse !== task.progress || progressParse === 0)) {
      pointingService.post({
        progress: progressParse,
        taskUniqueNumber: task.uniqueNumber,
        user: `/api/users/${JSON.parse(localStorage.getItem('user')!).id}`,
        subProject: `/api/sub_projects/${subProject.id}`,
      }).then((pointingData: any) => {
        getTask(task.id!)
        if(progress > 0) task.started = true;
      }).catch(err => {
        if(lastProgress) task.progress = lastProgress;
      })
    }
  }

  // @ts-ignore
  const toolTip = () => <ReactTooltip />;

  const clickUnfoldTask = (ev: any) => {
    if(!isTaskUnfolded) getTask(taskInput.id);
    setIsTaskUnfolded(!isTaskUnfolded)
  };

  const getTask = (taskId: string) => {
    taskService.getOne(taskId).then(({ data: taskData }) => {
        setTask(taskData)
        setCustomProgress(taskData.progress)
    }).catch(e => {})
  }

  useEffect(() => {
    setCanEdit(userService.isAdmin() || permissionList.some((permission: any ) => permission === task.booklet))
  }, []);

  const clickOnRexIcon = (task: ITask) => {
    if(task.rexCount > 0) {
      if(task.id !== undefined) taskService.getRexes(task.id).then(data => {
         showRexModal(task, data.data);
      }).catch((e: any) => {})
    } else {
      showRexModal(task, []);
    }
  }

  const updateTaskProgress = (progress: number) => {
    task.progress = progress;
  }

  const clickOnDocumentIcon = () => {
    if(task.folder === undefined) return;
    const list = documentList[task.folder];
    if(list.length == 1) {
      window.open(list[0].url, '_blank')
    } else {
      showModal(task.folder)
    }
  }

  const showMilestoneSignatureModal = (task: ITask) => {
    if(task.progress !== 100) {
      showSignatureModal(task);
    }
  }

  return (
      <IonCard>
        <IonCardHeader>
          <IonRow
              className="ion-align-items-center ion-justify-content-between task-card__header">
            <IonCardTitle>
              <IonLabel data-tip={breadcrumb} className="task-card__title black"
                        style={{marginRight: '10px'}}>{task.hierarchicalNumber} {task.taskLabel}</IonLabel>
            </IonCardTitle>

            <IonRow className="ion-align-items-center ion-justify-content-between">
              <IonButtons>
                {task.checkList !== null && task.checkList !== '' && <IonButton onClick={() => setCheckListModalOpen(true)}>
                  <IonIcon icon={listOutline} />
                </IonButton>}
                {task.reported && <IonButton>
                  <IonIcon color={"warning"} icon={warningOutline} />
                </IonButton>}
                {showModal && task.folder !== undefined && task.folder !== "" && task.folder in documentList && documentList[task.folder].length > 0 &&
                    <IonButton onClick={clickOnDocumentIcon}>
                      <IonIcon color={"dark"} icon={documentOutline} />
                    </IonButton>}
                {showRexModal && <IonButton onClick={() => clickOnRexIcon(task)}>
                  <IonIcon color={task.rexCount > 0 ? "success" : ""} icon={chatbubbleEllipsesOutline} />
                </IonButton>}
              </IonButtons>

              <Chip task={task}/>
            </IonRow>
          </IonRow>
        </IonCardHeader>

        <IonCardContent>
          <IonRow className="ion-justify-content-between ion-align-items-center">
            {canEdit && <>
              <div style={{flex: 'auto'}}>
                {isSelectingCustomAdvancement ? <>
                      <div style={{
                        padding: '0 16px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        background: 'rgba(0, 0, 0, 0.08)'
                      }}>
                        <IonLabel>Avancement personalisé <span style={{
                          color: "rgb(215, 215, 215)",
                          marginLeft: '16px'
                        }}>|</span></IonLabel>
                        <input max="100" min="0" type="number" placeholder="précisez l'avancement" value={customProgress}
                               onChange={handleCustomProgress} onKeyPress={e => {
                          if (e.key === "Enter") {
                            setIsSelectingCustomAdvancement(false);
                            submit(customProgress);
                          }
                        }} style={{
                          padding: '0',
                          height: '32px',
                          backgroundColor: 'transparent',
                          width: 'calc(100% - 200px)',
                          border: 'none',
                          textAlign: 'center',
                        }}/>
                      </div>
                    </>
                    :
                    (task.mileStone ?
                        <IonSegment mode="ios" color={chipColor(task)}
                                    value={task.progress?.toString()}>
                          <IonSegmentButton onClick={handleChangeToNone} value="0"
                                            className={(task.progress ?? 0) > 0 ? 'late' : ''}>
                            <IonLabel>{!task.started ? 'Démarrer ' : 'Réinitialiser'}</IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton onClick={() => showMilestoneSignatureModal(task)} value="100">
                            <IonLabel>100 %</IonLabel>
                          </IonSegmentButton>
                        </IonSegment>
                        :
                        <IonSegment mode="ios"
                                    color={chipColor(task)}
                                    value={task.progress?.toString()}>
                          <IonSegmentButton onClick={handleChangeToNone} value="0"
                                            className={(task.progress ?? 0) > 0 ? 'late' : ''}>
                            <IonLabel>{!task.started ? 'Démarrer ' : 'Réinitialiser'}</IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton onClick={() => handleChange(25)} value="25"
                                            className={(task.progress ?? 0) > 25 ? 'late' : ''}>
                            <IonLabel>25 %</IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton onClick={() => handleChange(50)} value="50"
                                            className={(task.progress ?? 0) > 50 ? 'late' : ''}>
                            <IonLabel>50 %</IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton onClick={() => handleChange(75)} value="75"
                                            className={(task.progress ?? 0) > 75 ? 'late' : ''}>
                            <IonLabel>75 %</IonLabel>
                          </IonSegmentButton>
                          <IonSegmentButton onClick={() => handleChange(100)} value="100">
                            <IonLabel>100 %</IonLabel>
                          </IonSegmentButton>
                        </IonSegment>)
                }
              </div>

              {!task.mileStone &&
                  (isSelectingCustomAdvancement ?
                      <>
                        <IonButton
                            type="submit"
                            mode="ios"
                            // fill="solid"
                            color="light"
                            size="small"
                            onClick={(e: any) => {
                              setIsSelectingCustomAdvancement(false);
                              submit(customProgress)
                            }}
                        >
                          <IonIcon icon={saveOutline}/>
                          <IonLabel style={{
                            marginLeft: "8px"
                          }}>Confirmer</IonLabel>
                        </IonButton>
                        <IonButton
                            mode="ios"
                            // fill="solid"
                            color="light"
                            size="small"
                            onClick={() => cancelCustomProgress()}
                        >
                          <IonIcon icon={trash}/>
                          <IonLabel style={{
                            marginLeft: "8px"
                          }}>Annuler</IonLabel>
                        </IonButton>
                      </>
                      :
                      <>
                        <button
                            className={"edit-button"}
                            onClick={(e) => {
                              setIsSelectingCustomAdvancement(true)
                            }}
                        >
                          <IonIcon icon={createOutline}/>
                        </button>
                        {!task.cancelled && <button
                            className={"edit-button"}
                            onClick={(e) => cancelTask()}
                        ><IonIcon icon={closeOutline}/></button>}
                      </>)
              }
            </>}
          </IonRow>

          <IonRow className="ion-justify-content-between ion-margin-top">
            <IonRow className={"ion-align-items-center"}>
              <IonLabel
                  className={"text-dark"}>{task.booklet && `${task.booklet}`}{task.resources && task.resources[0] != "" && ` | ${task.resources}`}</IonLabel>
            </IonRow>
            <IonRow className={"ion-align-items-center"}>
              <IonLabel style={{
                marginLeft: "8px",
                textTransform: "capitalize"
              }}>{formatDate(task?.startDate, (subProject.displayDays ? 'ddd ' : '') + 'D MMM' + (subProject.displayHours ? ' HH:mm' : ''))} → {formatDate(task?.endDate, (subProject.displayDays ? 'ddd ' : '') + 'D MMM' + (subProject.displayHours ? ' HH:mm' : ''))}</IonLabel>
            </IonRow>
            <IonLabel onClick={clickUnfoldTask} style={{textDecorationLine: 'underline', cursor: 'pointer'}}
                      className={"text-dark"}>Détail</IonLabel>
          </IonRow>

          {isTaskUnfolded && <>
            {task.checkList !== null && task.checkList !== '' && <>
              <IonButton onClick={() => setCheckListModalOpen(true)}>
                <IonText color="white">
                  <h4 style={{marginRight: 8}}>CheckList</h4>
                </IonText>
                <IonIcon color={'black'} icon={checkboxOutline} />
              </IonButton>
            </>}

            <IonText color="dark">
              <h4 style={{fontWeight: 'bold', padding: '16px 0 8px 0'}}>Opérations</h4>
            </IonText>
            <IonText color="medium">
              {task.operations && <p>{task.operations}</p>}
              {!task.operations && <p>Aucune opération n'a été décrite</p>}
            </IonText>

            {task.extraInformations && task.extraInformations.length !== 0 && <>
              <IonText color="dark">
                <h4 style={{fontWeight: 'bold', padding: '16px 0 8px 0'}}>Informations complémentaires</h4>
              </IonText>
              {task.extraInformations.map((info: any) =>
                  <IonRow>
                    <IonCol size="3">
                      <IonText color="dark">
                        <h5>{info.label}</h5>
                      </IonText>
                    </IonCol>
                    <IonCol>
                      <IonText color="medium">
                        <p>{info.value || 'non renseigné'}</p>
                      </IonText>
                    </IonCol>
                  </IonRow>
              )}
            </>}

            {task.mileStone && task.signature && task.signature.length !== 0 && <>
              <IonText color="dark">
                <h4 style={{fontWeight: 'bold', padding: '16px 0 8px 0'}}>Signature</h4>
              </IonText>
              <IonRow className={"ion-justify-content-start"}>
                <IonCol size={"2"}>
                  <a target="_blank" href={task.signature.image}><img src={task.signature.image} height="72"
                                                                      alt="Signature"/></a>
                </IonCol>
                <IonCol>
                  <IonText color="dark">
                    <h5>Par {task.signature.user}</h5>
                    <h5>le {task.signature.createdAt}</h5>
                  </IonText>
                </IonCol>
              </IonRow>
            </>}

            {subProject.reportTaskLabel != '' && subProject.cancelReportTaskLabel != '' && (
                <button
                    className={"edit-button"}
                    onClick={reportOrCancelTask}
                >
                  <IonText color={"dark"}>
                    <p>{task.reported ? subProject.cancelReportTaskLabel : subProject.reportTaskLabel}</p>
                  </IonText>
                </button>
            )}
          </>}
        </IonCardContent>
        {toolTip()}
        <CheckListTaskModal isOpen={checkListModalOpen} task={task} updateTaskProgress={updateTaskProgress} onDismiss={() => setCheckListModalOpen(false)} />
      </IonCard>
  );
}

export default TaskCard;
