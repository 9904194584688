import React, {useEffect, useRef, useState} from "react";
import {
    IonButton, IonCheckbox, IonIcon, IonItem, IonLabel,
    IonModal,
    IonRow,
    IonText
} from "@ionic/react";
import './MilestoneSignatureTaskModal.scss';
import {arrowBackOutline} from "ionicons/icons";
import Select from "react-select";
import SubProjectService from "../../services/Api/Project/SubProjectService";
import SignatureInput from "../Input/SignatureInput/SignatureInput";
import signatureService from "../../services/Api/SignatureService";
import pointingService from "../../services/Api/PointingService";
import {Controller} from "react-hook-form";
import {taskService} from "../../services";

const CheckListTaskModal: React.FC<any> = ({ isOpen = false, onDismiss, updateTaskProgress, task }) => {

    const [items, setItems] = useState({});

    useEffect(() => {
        if(task && isOpen) {
            taskService.getChecklist(task.id).then(d => setItems(d.data));
        }
    }, [task, isOpen])

    return <IonModal isOpen={isOpen} onDidDismiss={onDismiss} cssClass={"list-rex-modal"}>
        <div>
            <IonRow className={'ion-justify-content-between ion-align-items-center'}>
                <IonButton onClick={onDismiss}>
                    <IonIcon icon={arrowBackOutline} />
                </IonButton>
            </IonRow>

            <div>
                <div className={"form-group"}>
                    <IonLabel color={'dark'}>CheckList</IonLabel>
                    <div>
                        {Object.entries(items).map(([key, item]: any, i) => <IonRow key={i} className={'ion-align-items-center ion-justify-content-between'}>
                        <IonItem className={'w-full'}>
                            <IonLabel position="fixed">
                                {item.label}
                            </IonLabel>
                            <IonCheckbox
                                slot="end"
                                onClick={() => {
                                    taskService.toggleChecklist(task.id, { state: !item.state, itemId: key}).then(d => {
                                        if(d.data.success) {
                                            updateTaskProgress(d.data.progress);
                                        }
                                    }).catch(() => {
                                        item.state = !item.state;
                                    });
                                }}
                                checked={item.state}
                            />
                        </IonItem>
                        </IonRow>)}
                    </div>
                </div>
            </div>
        </div>
    </IonModal>;
}

export default CheckListTaskModal;
